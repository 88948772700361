// profile-menu-days/SnackMenuTabularDays.jsx
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SnackMenuTabularDays.css";
import { useStatusBoard } from "../../../utensils/StatusBoardContext"; // Importa el contexto de StatusBoard

const daysOfWeek = [
  {
    day: "Lunes",
    image: require("../../../ingredients/multimedia/images/profile/dia-1.png"),
    dayNumber: 1,
  },
  {
    day: "Martes",
    image: require("../../../ingredients/multimedia/images/profile/dia-2.png"),
    dayNumber: 2,
  },
  {
    day: "Miércoles",
    image: require("../../../ingredients/multimedia/images/profile/dia-3.png"),
    dayNumber: 3,
  },
  {
    day: "Jueves",
    image: require("../../../ingredients/multimedia/images/profile/dia-4.png"),
    dayNumber: 4,
  },
  {
    day: "Viernes",
    image: require("../../../ingredients/multimedia/images/profile/dia-5.png"),
    dayNumber: 5,
  },
  {
    day: "Sábado",
    image: require("../../../ingredients/multimedia/images/profile/dia-6.png"),
    dayNumber: 6,
  },
  {
    day: "Domingo",
    image: require("../../../ingredients/multimedia/images/profile/dia-7.png"),
    dayNumber: 7,
  },
];

const SnackMenuTabularDays = () => {
  const { setSelectedDay } = useStatusBoard(); // Obtén la función para actualizar el día seleccionado

  return (
    <div className="days-container">
      {daysOfWeek.map((day, index) => (
        <button
          key={index}
          className="day-button"
          title={day.day}
          onClick={() => setSelectedDay(day.dayNumber)} // Actualiza el día seleccionado
        >
          <img src={day.image} alt={day.day} className="day-image" />
        </button>
      ))}
    </div>
  );
};

export default SnackMenuTabularDays;
