// utensils/StatusBoardContext.js
import React, { createContext, useContext, useState } from "react";

// Crear el contexto para el estado del día seleccionado, la ingesta seleccionada y el ejercicio seleccionado
const StatusBoardContext = createContext();

// Hook para acceder al contexto
export const useStatusBoard = () => useContext(StatusBoardContext);

// Componente proveedor
export const StatusBoardProvider = ({ children }) => {
    const [selectedDay, setSelectedDay] = useState(1); // Día seleccionado por defecto
    const [selectedIngesta, setSelectedIngesta] = useState(1); // Ingesta seleccionada por defecto
    const [selectedExercise, setSelectedExercise] = useState(null); // Ejercicio seleccionado

    return (
        <StatusBoardContext.Provider value={{ selectedDay, setSelectedDay, selectedIngesta, setSelectedIngesta, selectedExercise, setSelectedExercise }}>
            {children}
        </StatusBoardContext.Provider>
    );
};
