// profile/SnackProfileInfoUser.jsx
import React from "react";
import { useUser } from "../../../utensils/UserContext"; // Accede al contexto de usuario
import "./SnackProfileInfoUser.css"; // Archivo CSS para estilos adicionales
import defaultGymIcon from "../../../ingredients/multimedia/images/profile/gym-icon.png"; // Importa la imagen por defecto
import SnackCredits from "../../credits/SnackCredits"; // Importa SnackCredits

const SnackProfileInfoUser = () => {
  console.log("Renderizando SnackProfileInfoUser...");

  const { user } = useUser(); // Obtén los datos del usuario desde el contexto
  console.log("Datos del usuario obtenidos:", user);

  // Verifica si el usuario está autenticado
  if (!user) {
    console.log("No hay usuario autenticado.");
    return <p>No hay usuario autenticado.</p>;
  }

  console.log("Usuario autenticado. Renderizando la tarjeta de información...");

  return (
    <div className="profile-info-card card shadow-lg p-4">
      <div className="d-flex align-items-center mb-3">
        <img
          src={user.UrlLogo || defaultGymIcon} // Usa el logo del usuario o la imagen por defecto
          alt="Logo del gimnasio"
          className="user-logo mr-3"
          onError={(e) => {
            e.target.src = defaultGymIcon;
          }} // Carga imagen por defecto si hay error
        />
        <h2 className="user-name mb-0">{user.Nombre}</h2>
        {console.log("Logo mostrado:", user.UrlLogo || defaultGymIcon)}
      </div>

      <div className="user-details">
        <p>
          <strong>Email:</strong> {user.Email}
        </p>
        {console.log("Email mostrado:", user.Email)}

        <p>
          <strong>Teléfono:</strong> {user.Descripcion}
        </p>
        {console.log("Teléfono mostrado:", user.Descripcion)}

        <p>
          <strong>Gimnasio:</strong> {user.Gimnasio}
        </p>
        {console.log("Gimnasio mostrado:", user.Gimnasio)}

        <p>
          <strong>La suscripción vence:</strong> {user.FechaPermitida}
        </p>
        {console.log("Fecha de vencimiento mostrada:", user.FechaPermitida)}
      </div>

      {/* Agrega el componente SnackCredits al final de la tarjeta */}
      <SnackCredits />
    </div>
  );
};

export default SnackProfileInfoUser;
