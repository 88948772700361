// Showcases/ShowcaseLogin.jsx
import React from "react"; // Importa la biblioteca de React
import SnackLogin from "../snacks/auth/SnackLogin"; // Importa el componente SnackLogin que se usará para la autenticación con PIN

// Define el componente ShowcaseLogin
const ShowcaseLogin = () => {
  return (
    <div className="showcase-login-container">
      {/* Renderiza el componente SnackLogin, que contiene el formulario de autenticación con PIN */}
      <SnackLogin />
    </div>
  );
};

export default ShowcaseLogin; // Exporta el componente ShowcaseLogin para que pueda ser usado en otras partes de la aplicación
