import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SnackLogin.css";
import logo from "../../ingredients/multimedia/images/logo.jpg";
import { useUser } from "../../utensils/UserContext"; //  Importa el contexto del usuario
import { useConfig } from "../../utensils/ConfigContext"; // Importa el contexto de configuración
import { FaWhatsapp } from "react-icons/fa"; // Importa el icono de WhatsApp
import SnackCredits from "../credits/SnackCredits";

const SnackLogin = () => {
  const [pin, setPin] = useState(""); // Estado para el PIN
  const [errorMessage, setErrorMessage] = useState(""); // Mensaje de error
  const [userData, setUserData] = useState(null); // Estado para almacenar datos del usuario
  const { loginUser } = useUser(); // Accede a la función para autenticar al usuario en el contexto
  const { apiToken, whatsappLink } = useConfig(); // Accede al token de API y enlace de WhatsApp desde el contexto de configuración

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!pin) {
      console.log("Error: El campo de PIN está vacío.");
      setErrorMessage("Por favor, ingresa un PIN.");
      return;
    }

    console.log("PIN ingresado:", pin);

    try {
      const response = await fetch(
        `https://api.baserow.io/api/database/rows/table/239437/?user_field_names=true&search=${pin}`,
        {
          method: "GET",
          headers: {
            Authorization: `Token ${apiToken}`,
          },
        }
      );

      if (!response.ok) {
        console.log("Error: No se pudo realizar la solicitud a la API.");
        setErrorMessage("Error en la solicitud. Inténtalo de nuevo.");
        return;
      }

      const data = await response.json();

      if (data && data.results && data.results.length > 0) {
        const fetchedUserData = data.results[0];
        const today = new Date();
        const allowedDate = new Date(fetchedUserData.FechaPermitida);

        if (today <= allowedDate) {
          console.log("PIN correcto. Autenticando usuario:", fetchedUserData);
          loginUser(fetchedUserData);
          setErrorMessage("");
          setUserData(null); // Limpia cualquier mensaje de expiración
        } else {
          console.log("Error: El acceso ha expirado.");
          setUserData(fetchedUserData); // Almacena los datos del usuario si la suscripción ha expirado
          setErrorMessage("El acceso ha expirado.");
        }
      } else {
        console.log("Error: PIN incorrecto o usuario no encontrado.");
        setErrorMessage("PIN incorrecto.");
      }
    } catch (error) {
      console.log("Error en la solicitud a la API:", error);
      setErrorMessage("Ocurrió un error al intentar conectar con la API.");
    }
  };

  // Genera el enlace de WhatsApp con los datos del usuario
  const generateWhatsappLink = () => {
    if (!userData) return whatsappLink;

    const message = `Hola, soy ${userData.Nombre}. Mi email es ${userData.Email} y pertenezco al gimnasio ${userData.Gimnasio}. Quisiera renovar mi suscripción.`;
    return `${whatsappLink}?text=${encodeURIComponent(message)}`;
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <div
        className="card shadow-lg p-4 text-center"
        style={{ maxWidth: "400px", width: "100%" }}
      >
        {/* Logo */}
        <div className="d-flex justify-content-center mb-3">
          <img src={logo} alt="Logo" className="logo img-fluid" />
        </div>

        {/* Título */}
        <h2 className="mb-2 text-primary">Autenticación</h2>

        {/* Descripción */}
        <p className="text-muted mb-4">
          Por favor, ingresa tu PIN para continuar
        </p>

        {/* Formulario de PIN */}
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <input
              type="password"
              className="form-control form-control-lg text-center"
              placeholder="••••"
              maxLength="10"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              style={{ letterSpacing: "0.5rem", fontSize: "1.5rem" }}
            />
          </div>

          {/* Mensaje de error con alerta de Bootstrap */}
          {errorMessage && (
            <div className="alert alert-danger" role="alert">
              {errorMessage}
            </div>
          )}

          {/* Botón de envío */}
          <button type="submit" className="btn btn-primary btn-lg w-100">
            Ingresar
          </button>
        </form>

        {/* Enlace de renovación por WhatsApp en caso de expiración */}
        {userData && (
          <div className="mt-4">
            <a
              href={generateWhatsappLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-success d-flex align-items-center justify-content-center mt-3"
            >
              <FaWhatsapp className="mr-2" /> ¡Deseo renovar mi suscripción!
            </a>
          </div>
        )}
        {/* Credits */}
        <SnackCredits />
      </div>
    </div>
  );
};

export default SnackLogin;
