// Importaciones necesarias
import React, { useState, useEffect, useRef } from "react";
import { useConfig } from "../../../../utensils/ConfigContext";
import { useStatusBoard } from "../../../../utensils/StatusBoardContext";
import axios from "axios";
import { Alert } from "react-bootstrap"; // Importar Alert de Bootstrap para mostrar alertas
import "bootstrap/dist/css/bootstrap.min.css"; // Importar estilos de Bootstrap
import "./ProfileUserDetailWorkout.css"; // Archivo CSS para estilos personalizados
import LottieLoader from "../../../lottie/SnackLottieLoader";

// Componente principal ProfileUserDetailWorkout
const ProfileUserDetailWorkout = ({ openAccordion }) => {
  const { apiToken } = useConfig(); // Obtener el token de API desde el contexto de configuración
  const { selectedExercise, selectedDay } = useStatusBoard(); // Obtener el ejercicio y el día seleccionados desde el contexto

  // Definición de estados
  const [exerciseDetails, setExerciseDetails] = useState(null); // Estado para almacenar los detalles del ejercicio
  const [weight, setWeight] = useState(""); // Estado para el peso ingresado por el usuario
  const [alertMessage, setAlertMessage] = useState(null); // Estado para el mensaje de alerta
  const [alertVariant, setAlertVariant] = useState("success"); // Estado para el tipo de alerta (éxito o error)
  const [yesterdayLoad, setYesterdayLoad] = useState("No data"); // Estado para almacenar la carga de ayer
  const alertRef = useRef(null); // Referencia para el contenedor de la alerta, usado para desplazar el scroll

  // Array con los nombres de los días
  const dayNames = [
    "Día 1",
    "Día 2",
    "Día 3",
    "Día 4",
    "Día 5",
    "Día 6",
    "Día 7",
  ];

  // useEffect para obtener los detalles del ejercicio seleccionado
  useEffect(() => {
    if (selectedExercise) {
      axios
        .get(
          `https://api.baserow.io/api/database/rows/table/241772/?user_field_names=true&search=${selectedExercise.NombreEjercicio}`,
          {
            headers: { Authorization: `Token ${apiToken}` },
          }
        )
        .then((response) => {
          const data = response.data.results[0];
          setExerciseDetails(data);
        })
        .catch((error) => {
          console.error("Error al obtener los detalles del ejercicio:", error);
        });
    }
  }, [selectedExercise, apiToken]);

  // useEffect para consultar la carga registrada del año actual
  useEffect(() => {
    if (selectedExercise && selectedExercise.NombreUsuario) {
      const thisYearUrl = `https://api.baserow.io/api/database/rows/table/244317/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22date_is%22%2C%22field%22%3A%22Fecha%22%2C%22value%22%3A%22America%2FBogota%3F%3Fthis_year%22%7D%5D%2C%22groups%22%3A%5B%5D%7D&search=${selectedExercise.NombreUsuario}`;

      axios
        .get(thisYearUrl, {
          headers: { Authorization: `Token ${apiToken}` },
        })
        .then((response) => {
          // Buscar la última tarea que coincida con el nombre del ejercicio actual
          const matchingTasks = response.data.results.filter(
            (task) => task.NombreEjercicio === selectedExercise.NombreEjercicio
          );

          if (matchingTasks.length > 0) {
            const lastTask = matchingTasks[matchingTasks.length - 1]; // Toma la última tarea
            setYesterdayLoad(lastTask.Carga + " Kg");
          } else {
            setYesterdayLoad("0 Kg");
          }
        })
        .catch((error) => {
          console.error("Error al obtener la carga del año actual:", error);
        });
    }
  }, [selectedExercise, apiToken]);

  // useEffect para desplazarse al final cuando se establece un mensaje de alerta
  useEffect(() => {
    if (alertMessage) {
      alertRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [alertMessage]);

  if (!selectedExercise || !exerciseDetails)
    return <LottieLoader openAccordion={openAccordion} />;

  const cadencia =
    selectedExercise.TipoContraccion === "Isométrica"
      ? 30
      : selectedExercise.TipoContraccion === "Pliométrica"
      ? 1
      : selectedExercise.TipoContraccion === "Isotónica"
      ? 4
      : 0;

  const densidadTrabajo =
    (
      (selectedExercise.Repeticiones * selectedExercise.Series * cadencia) /
      60
    ).toFixed(1) + " min.";
  const densidadDescanso =
    (selectedExercise.Series * selectedExercise.IntervaloDescanso).toFixed(1) +
    " min.";

  const handleSave = async () => {
    console.log("Inicio de guardado de tarea.");

    if (!weight) {
      console.log("No se ingresó peso. Mostrando alerta de error.");
      setAlertMessage("Por favor, ingrese el peso antes de guardar.");
      setAlertVariant("danger");
      return;
    }

    const searchUrl = `https://api.baserow.io/api/database/rows/table/244317/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22date_is%22%2C%22field%22%3A%22Fecha%22%2C%22value%22%3A%22America%2FBogota%3F%3Ftoday%22%7D%5D%2C%22groups%22%3A%5B%5D%7D&search=${selectedExercise.NombreUsuario}`;

    console.log("URL de consulta:", searchUrl);

    try {
      const searchResponse = await axios.get(searchUrl, {
        headers: { Authorization: `Token ${apiToken}` },
      });

      console.log("Respuesta de consulta:", searchResponse.data);

      const today = new Date().toLocaleDateString("en-CA", {
        timeZone: "America/Bogota",
      });
      console.log("Fecha ajustada a zona horaria de Bogotá:", today);

      // Filtrar la tarea que coincide con el ejercicio actual
      const existingTask = searchResponse.data.results.find(
        (task) => task.NombreEjercicio === selectedExercise.NombreEjercicio
      );

      if (existingTask) {
        console.log(
          "Tarea encontrada para el ejercicio. Procediendo a actualizar."
        );

        // Actualizar la tarea existente
        const updateResponse = await axios.patch(
          `https://api.baserow.io/api/database/rows/table/244317/${existingTask.id}/?user_field_names=true`,
          { Carga: weight },
          {
            headers: {
              Authorization: `Token ${apiToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Tarea actualizada exitosamente:", updateResponse.data);
        setAlertMessage("Tarea actualizada exitosamente.");
        setAlertVariant("success");
      } else {
        console.log(
          "No se encontró tarea para hoy. Procediendo a crear una nueva tarea."
        );

        // Crear una nueva tarea
        const createResponse = await axios.post(
          "https://api.baserow.io/api/database/rows/table/244317/?user_field_names=true",
          {
            NombreEjercicio: selectedExercise.NombreEjercicio,
            Series: selectedExercise.Series,
            Repeticiones: selectedExercise.Repeticiones,
            Carga: weight,
            IntervaloDescanso: selectedExercise.IntervaloDescanso,
            Metodo: selectedExercise.Metodo,
            Intensidad: selectedExercise.Intensidad,
            TipoContraccion: selectedExercise.TipoContraccion,
            Dia: dayNames[selectedDay - 1],
            Usuario: selectedExercise.NombreUsuario,
            Fecha: today,
            GrupoMuscular: selectedExercise.GrupoMuscular,
          },
          {
            headers: {
              Authorization: `Token ${apiToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Tarea registrada exitosamente:", createResponse.data);
        setAlertMessage("Tarea registrada exitosamente.");
        setAlertVariant("success");
      }

      setWeight("");
    } catch (error) {
      console.error("Error al guardar la tarea:", error);
      if (error.response) {
        console.error(
          "Detalle del error de la respuesta:",
          error.response.data
        );
        console.error("Código de estado:", error.response.status);
      }
      setAlertMessage("Hubo un error al guardar la tarea. Intente nuevamente.");
      setAlertVariant("danger");
    }

    console.log("Proceso de guardado de tarea completado.");
  };

  return (
    <div className="exercise-detail-container">
      {/* Sección multimedia */}
      <div className="media-section">
        <h3>{selectedExercise.NombreEjercicio}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: exerciseDetails.Contenido }}
          className="exercise-video"
        />
        <p className="exercise-subtitle">{exerciseDetails.Subtitulo}</p>
      </div>

      {/* Grid de detalles del ejercicio */}
      <div className="details-grid">
        <div className="detail-item">
          <strong>Series:</strong> {selectedExercise.Series}
        </div>
        <div className="detail-item">
          <strong>Repeticiones:</strong> {selectedExercise.Repeticiones}
        </div>
        <div className="detail-item">
          <strong>Intervalo de descanso:</strong>{" "}
          {selectedExercise.IntervaloDescanso} min.
        </div>
        <div className="detail-item">
          <strong>Método:</strong> {selectedExercise.Metodo}
        </div>
        <div className="detail-item">
          <strong>Intensidad:</strong> {selectedExercise.Intensidad}
        </div>
        <div className="detail-item">
          <strong>Tipo de contracción:</strong>{" "}
          {selectedExercise.TipoContraccion}
        </div>
        <div className="detail-item">
          <strong>Día seleccionado:</strong> {dayNames[selectedDay - 1]}
        </div>
        <div className="detail-item">
          <strong>Usuario:</strong> {selectedExercise.Usuario}
        </div>
      </div>

      {/* Sección de cálculos adicionales */}
      <div className="calculations-section">
        <h4>Más información</h4>
        <div className="calculation-item">
          <strong>Cadencia:</strong> {cadencia} segundos
        </div>
        <div className="calculation-item">
          <strong>Densidad de trabajo:</strong> {densidadTrabajo}
        </div>
        <div className="calculation-item">
          <strong>Densidad de descanso:</strong> {densidadDescanso}
        </div>
        <div className="calculation-item">
          <strong>Última carga registrada:</strong> {yesterdayLoad}
        </div>
      </div>

      {/* Entrada para peso y botón de guardar */}
      <div className="input-section">
        <label htmlFor="weight">Peso (Kg):</label>
        <input
          type="number"
          id="weight"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          className="weight-input"
        />
        <button onClick={handleSave} className="save-button">
          Guardar
        </button>
      </div>

      {/* Alerta de éxito o error debajo del botón de guardar */}
      {alertMessage && (
        <Alert
          ref={alertRef}
          variant={alertVariant}
          onClose={() => setAlertMessage(null)}
          dismissible
          className="mt-3"
        >
          {alertMessage}
        </Alert>
      )}
    </div>
  );
};

export default ProfileUserDetailWorkout;
