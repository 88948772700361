import React, { createContext, useContext, useState, useEffect } from "react";

// Crear el contexto de usuario
const UserContext = createContext(null);

// Hook para acceder al contexto de usuario
export const useUser = () => useContext(UserContext);

// Componente proveedor de contexto de usuario
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null); // Estado para el usuario autenticado

    // Función para autenticar al usuario y guardar en sessionStorage
    const loginUser = (userData) => {
        console.log("Llamada a loginUser con datos:", userData);
        setUser(userData); // Establece el estado del usuario
        sessionStorage.setItem("user", JSON.stringify(userData)); // Guarda en sessionStorage
        console.log("Usuario guardado en sessionStorage y en estado de contexto.");
    };

    // Función para cerrar sesión y limpiar sessionStorage
    const logoutUser = () => {
        console.log("Cerrando sesión del usuario.");
        setUser(null); // Limpia el estado del usuario
        sessionStorage.removeItem("user"); // Elimina la sesión de sessionStorage
        console.log("Usuario eliminado de sessionStorage.");
    };

    // Cargar el usuario desde sessionStorage al iniciar la app
    useEffect(() => {
        console.log("Verificando si hay una sesión de usuario almacenada...");
        const storedUser = sessionStorage.getItem("user");
        if (storedUser) {
            console.log("Sesión encontrada. Restaurando el usuario desde sessionStorage.");
            setUser(JSON.parse(storedUser)); // Restaura el estado de usuario si está guardado
        } else {
            console.log("No se encontró una sesión de usuario activa.");
        }
    }, []); // Este efecto se ejecuta solo una vez al montar el componente

    return (
        <UserContext.Provider value={{ user, loginUser, logoutUser }}>
            {children}
        </UserContext.Provider>
    );
};
