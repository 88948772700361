// SnackProfileNutritionImage.jsx
import React from "react";
import nutritionImage from "../../../../ingredients/multimedia/images/nutrition/lista-alimentos-sustitutos.png";
import "./SnackProfileUserNutritionImage.css"; // Importa el archivo CSS para estilos adicionales

const SnackProfileNutritionImage = () => {
  return (
    <div className="nutrition-image-container">
      <img
        src={nutritionImage}
        alt="Tabla de alimentos y sustitutos"
        className="nutrition-image"
      />
    </div>
  );
};

export default SnackProfileNutritionImage;
