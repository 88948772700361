import React from "react"; // Importa React
import ShowcaseLogin from "../showcases/ShowcaseLogin"; // Importa el componente de login
import ShowcaseProfile from "../showcases/ShowcaseProfile"; // Importa el componente de perfil
import { useUser } from "../utensils/UserContext"; // Importa el contexto de usuario

function App() {
  const { user } = useUser(); // Accede al estado de usuario desde el contexto

  return (
    <div>
      {/* Verifica si el usuario está autenticado */}
      {user ? (
        // Si el usuario está autenticado, muestra el perfil
        <ShowcaseProfile />
      ) : (
        // Si el usuario no está autenticado, muestra la pantalla de login
        <ShowcaseLogin />
      )}
    </div>
  );
}

export default App; // Exporta el componente App como el componente raíz de la aplicación
