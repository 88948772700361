// Importaciones necesarias
import React, { useState, useEffect } from "react";
import { useConfig } from "../../../../utensils/ConfigContext";
import { useUser } from "../../../../utensils/UserContext"; // Importar el contexto de usuario
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css"; // Importar estilos de Bootstrap

// Componente principal SnackProfileActivity
const SnackProfileActivity = () => {
  const { apiToken } = useConfig(); // Obtener el token de API desde el contexto de configuración
  const { user } = useUser(); // Obtener el usuario autenticado desde el contexto
  const [groupTotals, setGroupTotals] = useState([]); // Estado para almacenar las sumatorias por grupo muscular
  const [overallTotal, setOverallTotal] = useState({
    series: 0,
    repeticiones: 0,
    carga: 0,
  }); // Estado para el total general
  const [selectedRange, setSelectedRange] = useState(""); // Estado para el rango de fechas seleccionado
  const [selectedGroup, setSelectedGroup] = useState(""); // Estado para el grupo muscular seleccionado

  // Opciones de rango de fecha
  const dateRanges = {
    today: "today",
    yesterday: "yesterday",
    one_week_ago: "one_week_ago",
    this_week: "this_week",
    one_month_ago: "one_month_ago",
    this_month: "this_month",
    one_year_ago: "one_year_ago",
    this_year: "this_year",
  };

  // Opciones de grupos musculares
  const muscleGroups = [
    "Tríceps",
    "Hombro",
    "Isquiotibiales",
    "Cuádriceps",
    "Glúteos",
    "Pantorrilla",
    "Abdominales",
    "Pecho",
    "Bíceps",
    "Aductor/Abductor",
    "Espalda",
    "Lumbar",
    "Oblicuos",
  ];

  // Función para obtener y calcular las sumatorias basadas en el rango de fecha, grupo muscular y correo del usuario
  const fetchTasks = async () => {
    if (!selectedRange || !apiToken || !user?.Email) return;

    const dateFilterUrl = `https://api.baserow.io/api/database/rows/table/244317/?user_field_names=true&filters=%7B%22filter_type%22%3A%22AND%22%2C%22filters%22%3A%5B%7B%22type%22%3A%22date_is%22%2C%22field%22%3A%22Fecha%22%2C%22value%22%3A%22America%2FBogota%3F%3F${dateRanges[selectedRange]}%22%7D%5D%2C%22groups%22%3A%5B%5D%7D&search=${user.Email}`;

    try {
      const response = await axios.get(dateFilterUrl, {
        headers: { Authorization: `Token ${apiToken}` },
      });

      // Filtrar las tareas por grupo muscular si está seleccionado
      const filteredTasks = selectedGroup
        ? response.data.results.filter(
            (task) => task.GrupoMuscular === selectedGroup
          )
        : response.data.results;

      // Agrupar las tareas por grupo muscular y calcular los totales por grupo
      const groupTotals = filteredTasks.reduce((acc, task) => {
        const group = task.GrupoMuscular;
        if (!acc[group]) {
          acc[group] = { series: 0, repeticiones: 0, carga: 0 };
        }
        acc[group].series += Number(task.Series); // Convertir a número antes de sumar
        acc[group].repeticiones += Number(task.Repeticiones);
        acc[group].carga += Number(task.Carga);
        return acc;
      }, {});

      // Convertir los resultados en un array y calcular el total general
      const totalsArray = Object.entries(groupTotals).map(
        ([group, values]) => ({
          group,
          ...values,
        })
      );

      const overallTotal = totalsArray.reduce(
        (acc, item) => {
          acc.series += item.series;
          acc.repeticiones += item.repeticiones;
          acc.carga += item.carga;
          return acc;
        },
        { series: 0, repeticiones: 0, carga: 0 }
      );

      setGroupTotals(totalsArray);
      setOverallTotal(overallTotal);
    } catch (error) {
      console.error("Error al obtener las tareas:", error);
    }
  };

  // useEffect para cargar las tareas al seleccionar el rango o grupo
  useEffect(() => {
    fetchTasks();
  }, [selectedRange, selectedGroup, apiToken, user]);

  // Función para imprimir el reporte
  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="activity-container">
      <h3>Historial de Actividades</h3>

      {/* Selectores de rango de fechas y grupo muscular */}
      <div className="filters-container mb-3">
        <label htmlFor="dateRange">Rango de Fecha:</label>
        <select
          id="dateRange"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
          className="form-select"
        >
          <option value="">Select date range</option>
          {Object.keys(dateRanges).map((range) => (
            <option key={range} value={range}>
              {range
                .replace(/_/g, " ")
                .replace(/\b\w/g, (l) => l.toUpperCase())}
            </option>
          ))}
        </select>

        <label htmlFor="muscleGroup" className="mt-2">
          Grupo Muscular:
        </label>
        <select
          id="muscleGroup"
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          className="form-select"
        >
          <option value="">Todos</option>
          {muscleGroups.map((group) => (
            <option key={group} value={group}>
              {group}
            </option>
          ))}
        </select>
      </div>

      {/* Tabla de sumatorias por grupo muscular */}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Grupo Muscular</th>
            <th>Total Series</th>
            <th>Total Repeticiones</th>
            <th>Total Carga Registrada (Kg)</th>
          </tr>
        </thead>
        <tbody>
          {groupTotals.length > 0 ? (
            groupTotals.map((item) => (
              <tr key={item.group}>
                <td>{item.group}</td>
                <td>{item.series}</td>
                <td>{item.repeticiones}</td>
                <td>{item.carga} Kg</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No se encontraron actividades para el filtro seleccionado.
              </td>
            </tr>
          )}
        </tbody>
        {/* Fila de totales generales */}
        <tfoot>
          <tr>
            <th>Total General</th>
            <th>{overallTotal.series}</th>
            <th>{overallTotal.repeticiones}</th>
            <th>{overallTotal.carga} Kg</th>
          </tr>
        </tfoot>
      </table>

      {/* Botones de actualizar e imprimir */}
      <div className="button-group mb-3">
        <button
          onClick={fetchTasks}
          className="btn btn-primary action-button update-button"
        >
          Actualizar
        </button>
        <button
          onClick={handlePrint}
          className="btn btn-secondary action-button print-button"
        >
          Imprimir Reporte
        </button>
      </div>
    </div>
  );
};

export default SnackProfileActivity;
