import React from "react";
import "./SnackCredits.css"; // Archivo CSS para estilos del componente

const SnackCredits = () => {
  return (
    <div className="credits-container">
      <p className="credits-text">
        Powered by{" "}
        <a
          href="https://www.instagram.com/maurollc"
          target="_blank"
          rel="noopener noreferrer"
        >
          Maobits
        </a>
      </p>
    </div>
  );
};

export default SnackCredits;
