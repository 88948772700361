// src/components/LottieLoader.jsx
import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import olympicAthleteAnimation from "../../ingredients/multimedia/lotties/olympic-athlete.json";

const LottieLoader = ({ openAccordion }) => {
  return (
    <div className="lottie-loader">
      <Player
        autoplay
        loop
        src={olympicAthleteAnimation}
        style={{ height: "200px", width: "200px" }}
      />
      <p>
        ¡Elige un ejercicio para comenzar!{" "}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            openAccordion();
          }}
        >
          haz clic aquí
        </a>
      </p>
    </div>
  );
};

export default LottieLoader;
