// index.js
import React from "react";
import { createRoot } from "react-dom/client";
import App from "../snacks/App";
import { UserProvider } from "../utensils/UserContext";
import { ConfigProvider } from "../utensils/ConfigContext";
import { StatusBoardProvider } from "../utensils/StatusBoardContext"; // Nuevo proveedor de estados

// Obtén el contenedor raíz desde el DOM
const container = document.getElementById("root");
const root = createRoot(container);

// Renderiza la aplicación con todos los proveedores de contexto
root.render(
    <ConfigProvider>
        <UserProvider>
            <StatusBoardProvider>
                <App />
            </StatusBoardProvider>
        </UserProvider>
    </ConfigProvider>
);
