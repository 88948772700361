// SnackProfile.jsx
import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab, Accordion } from "react-bootstrap";
import SnackNavBarProfile from "./profile-navbar/SnackProfileNavBar";
import SnackProfileInfoUser from "./profile-user-info/SnackProfileInfoUser";
import SnackMenuTabularDays from "./profile-menu-days/SnackMenuTabularDays";
import SnackProfileUserWorkouts from "./profile-user-workouts/SnackProfileUserWorkouts";
import ProfileUserDetailWorkout from "./profile-user-workouts/profile-user-detail-workout/ProfileUserDetailWorkout";
import { useStatusBoard } from "../../utensils/StatusBoardContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SnackProfile.css";
import SnackProfileActivity from "./profile-user-workouts/profile-user-activity/SnackProfileActivity";
import SnackUserEvaluation from "./profile-user-info/profile-user-evaluation/SnackUserEvaluation";
import SnackProfileUserNutrition from "./profile-user-nutrition/SnackProfileUserNutrition";
import SnackMenuTabularDaysNutrition from "./profile-menu-days-nutrition/SnackMenuTabularDaysNutrition";
import SnackProfileNutritionImage from "./profile-user-nutrition/profile-user-nutrition-image/SnackProfileUserNutritionImage";

const SnackProfile = () => {
  const [activeKey, setActiveKey] = useState("0");
  const [nutritionKey, setNutritionKey] = useState("0"); // Índice activo para el segundo acordeón
  const [exerciseTitle, setExerciseTitle] = useState("Mi ejercicio actual");
  const { selectedExercise } = useStatusBoard();
  const headerRef = useRef(null);

  useEffect(() => {
    if (selectedExercise) {
      setExerciseTitle(
        `Mi ejercicio actual: ${selectedExercise.NombreEjercicio}`
      );
      setActiveKey("2"); // Cambiar al acordeón de detalles
    }
  }, [selectedExercise]);

  // Función para abrir la pestaña de "Mi rutina de entrenamiento"
  const openAccordion = () => {
    setActiveKey("1");
  };

  return (
    <div className="profile-container vh-100">
      <SnackNavBarProfile />

      {/* Panel Tabular */}
      <Tabs
        defaultActiveKey="fitness"
        id="profile-tabs"
        className="tab-container m-3"
      >
        {/* Tab Fitness */}
        <Tab eventKey="fitness" title="Fitness" className="tab-button">
          {/* Primer acordeón: Información y entrenamiento */}
          <Accordion
            activeKey={activeKey}
            onSelect={setActiveKey}
            className="m-3"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Mi perfil fitness</Accordion.Header>
              <Accordion.Body>
                <SnackProfileInfoUser />
                <SnackUserEvaluation />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Mi rutina de entrenamiento</Accordion.Header>
              <Accordion.Body>
                <SnackMenuTabularDays />
                <SnackProfileUserWorkouts />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" ref={headerRef}>
              <Accordion.Header>{exerciseTitle}</Accordion.Header>
              <Accordion.Body>
                <ProfileUserDetailWorkout openAccordion={openAccordion} />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Mi actividad fitness</Accordion.Header>
              <Accordion.Body>
                <SnackProfileActivity />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>

        {/* Tab Nutrición */}
        <Tab eventKey="nutrition" title="Nutrición" className="tab-button">
          {/* Segundo acordeón: Plan de nutrición */}
          <Accordion
            activeKey={nutritionKey}
            onSelect={setNutritionKey}
            className="m-3"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Mi plan de nutrición</Accordion.Header>
              <Accordion.Body>
                <SnackMenuTabularDaysNutrition />
                <SnackProfileUserNutrition />
                {/* Aquí podrías insertar un componente específico, por ejemplo: <NutritionPlan /> */}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>Lista de alimentos sustitutos</Accordion.Header>
              <Accordion.Body>
                <SnackProfileNutritionImage />
                {/* Aquí podrías insertar un componente específico, por ejemplo: <NutritionPlan /> */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Tab>
      </Tabs>
    </div>
  );
};

export default SnackProfile;
