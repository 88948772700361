// profile/SnackProfileUserWorkouts.jsx
import React, { useEffect, useState } from "react";
import { useStatusBoard } from "../../../utensils/StatusBoardContext";
import { useConfig } from "../../../utensils/ConfigContext";
import { useUser } from "../../../utensils/UserContext";
import SnackProfileUserWorkoutsCard from "./profile-workouts-card/SnackProfileUserWorkoutsCard"; // Importa el componente de tarjeta
import "bootstrap/dist/css/bootstrap.min.css";
import "./SnackProfileUserWorkouts.css";

const SnackProfileUserWorkouts = () => {
  const { selectedDay } = useStatusBoard();
  const { apiToken } = useConfig();
  const { user } = useUser();
  const [trainingPlan, setTrainingPlan] = useState([]);
  const [filteredWorkouts, setFilteredWorkouts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTrainingPlan = async () => {
      if (!user || !user.Email) return;

      setLoading(true);
      try {
        const response = await fetch(
          `https://api.baserow.io/api/database/rows/table/241994/?user_field_names=true&search=${user.Email}`,
          {
            method: "GET",
            headers: { Authorization: `Token ${apiToken}` },
          }
        );

        if (!response.ok)
          throw new Error("Error al obtener el plan de entrenamiento");

        const data = await response.json();
        setTrainingPlan(data.results);
      } catch (error) {
        console.error(
          "Error al obtener el plan de entrenamiento:",
          error.message
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTrainingPlan();
  }, [user, apiToken]);

  useEffect(() => {
    if (selectedDay && trainingPlan.length > 0) {
      const workoutsForSelectedDay = trainingPlan.filter(
        (workout) =>
          workout.Dia === `dia-${selectedDay}` &&
          workout.NombreUsuario === user.Email
      );
      setFilteredWorkouts(workoutsForSelectedDay);
      console.log(
        `Entrenamientos para el día ${selectedDay}:`,
        workoutsForSelectedDay
      );
    }
  }, [selectedDay, trainingPlan, user.Email]);

  return (
    <div className="training-plan-container">
      <h2>Entrenamientos del Día {selectedDay}</h2>
      {loading ? (
        <p>Cargando plan de entrenamiento...</p>
      ) : filteredWorkouts && filteredWorkouts.length > 0 ? (
        <div className="workouts-cards-container">
          {filteredWorkouts.map((exercise) => (
            <SnackProfileUserWorkoutsCard
              key={exercise.id}
              exercise={exercise}
            />
          ))}
        </div>
      ) : (
        <p>No se encontraron entrenamientos para este día.</p>
      )}
    </div>
  );
};

export default SnackProfileUserWorkouts;
