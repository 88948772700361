import React, { createContext, useContext } from "react";

// Crear el contexto de configuración
const ConfigContext = createContext();

// Hook para acceder al contexto de configuración
export const useConfig = () => useContext(ConfigContext);

// Componente proveedor de configuración que contiene el token
export const ConfigProvider = ({ children }) => {
    // Almacena el token de acceso aquí
    const config = {
        apiToken: "9kaem4SBAGvcfEfFB6BEgt3i0PZja3c8", // Token de acceso a la API de Baserow
        whatsappLink: "https://wa.me/573153774638", // Reemplaza con el número de teléfono de WhatsApp deseado
    };

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};
