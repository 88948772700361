// profile-navbar/SnackNavBarProfile.jsx
import React from "react";
import logo from "../../../ingredients/multimedia/images/logo.jpg"; // Asegúrate de que la ruta sea correcta
import "bootstrap/dist/css/bootstrap.min.css";
import "./SnackProfileNavBar.css"; // Archivo CSS para los estilos específicos del Navbar
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useUser } from "../../../utensils/UserContext"; // Importa el contexto de usuario

const SnackNavBarProfile = () => {
  const { logoutUser } = useUser(); // Accede a la función de cierre de sesión

  // Maneja el clic en "Cerrar sesión"
  const handleLogout = () => {
    logoutUser(); // Llama a la función para cerrar sesión
  };

  return (
    <Navbar expand="lg" className="w-100 custom-navbar">
      <Navbar.Brand href="#home" className="d-flex align-items-center">
        <img src={logo} alt="Logo" className="logo mr-2" />
        <span className="brand-name">Mi tablero</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {/* Menú de opciones dentro del Navbar */}
        <Nav className="basic-nav-dropdown">
          <Nav.Link href="#logout" onClick={handleLogout}>
            Cerrar sesión
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default SnackNavBarProfile;
