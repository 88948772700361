import React from "react"; // Importa la biblioteca de React
import SnackProfile from "../snacks/profile/SnackProfile"; // Importa el componente SnackProfile que se usará para el tablero del usuario autenticado.
import "../glamour/GlamourShowcaseProfile.css";

// Define el componente ShowcaseProfile
const ShowcaseProfile = () => {
  return (
    <div className="showcase-prfile-container">
      {/* Renderiza el componente SnackProfile, que contiene el tablero del usuario autenticado. */}
      <SnackProfile />
    </div>
  );
};

export default ShowcaseProfile; // Exporta el componente ShowcaseProfile para que pueda ser usado en otras partes de la aplicación
